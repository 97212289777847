import {fetchProtectedData, postData} from '../../api/fetch';
import {defer, useFetcher, useLoaderData, useNavigate, useParams, useRouteLoaderData} from 'react-router-dom';
import {useOpenModal} from '../../hooks/useOpenModal';
import {useEffect, useRef, useState} from 'react';
import {useQuestionnaireContext} from '../../contexts/QuestionnaireContext';
import {useForm} from 'react-hook-form';
import FormModal from '../../components/Modal/FormModal';
import {MarkdownText, Text} from '../../components/Text/Text';
import styled from 'styled-components';
import {Button, buttonVariants} from '../../components/Button/Button';
import {Heading1, Heading2} from '../../components/Heading/Heading';
import {ConfirmBeforeCloseModal} from './Partials/ConfirmBeforeCloseModal';
import {ConfirmBeforeSubmitModal} from './Partials/ConfirmBeforeSubmitModal';
import {Permissions, Statuses} from '../../constants/enums';
import {DefaultFormFields} from '../../components/DefaultFormFields/DefaultFormFields';
import {DivaFormFields} from '../../components/DivaFormFields/DivaFormFields';
import {TableOfContents, TableOfContentsItem} from '../../components/TableOfContents/TableOfContents';
import useScrollSpy from '../../hooks/useScrollSpy';
import {checkRequiredAccount} from '../Root/Root';

const Box = styled.div`
    flex: 1 1 0;
`;

const StyledButton = styled(Button)`
    ${({$variant}) => buttonVariants($variant)};
    border: none;
    width: 100%;
`;

const Steps = styled(Heading1).attrs({
    as: "h3",
    $align: "center",
    $noMargin: true
})`
    flex: 1 0 auto;
`;

const StepTitle = styled(Heading2).attrs({
    as: "h4",
    $noMargin: true
})`
    margin-bottom: 10px;
`;

const ModalHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 45px;
`;

const PatientName = styled(Text).attrs({
    $noMargin: true,
})`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 1 1 0;
`;

const EmptyBox = styled.div`
    flex: 1 1 0;
`;

export async function questionsAction({request, params}) {
    let formData = await request.formData();
    let intent = formData.get("intent");

    switch(intent) {
        case "fill-in-questionnaire": {
            const midTerm = !!formData.get('shouldSaveMidTerm') ? 1 : 0
            formData.delete("intent");
            formData.delete('shouldSaveMidTerm')
            return await postData(request, `patient/${params.patientUUID}/questionnaire/${params.questionnaireUUID}?midTermSave=${midTerm}`, formData, { type: 'formData' });
        }

        default:
            return {"default": true};
    }
}

export async function questionsLoader({request, params}) {
    await checkRequiredAccount(Permissions.PATIENTQUESTIONNAIRE_READ);

    const questionsPromise = fetchProtectedData(request, `patient/${params.patientUUID}/questionnaire/${params.questionnaireUUID}/questions`);

    return defer({questionsData: await questionsPromise});
}

const Questions = () => {
    const {questionsData} = useLoaderData();
    const {questionnaireData} = useRouteLoaderData("questionnaire");
    const patient = questionnaireData?.patient?.name;
    const {patientUUID, questionnaireUUID} = useParams();
    const navigate = useNavigate();
    const isDiva = questionsData?.identifier === "Diva";

    // Handle questions modal and confirmation before submit modal
    const {isOpen, handleOpen, handleClose} = useOpenModal();
    const {isOpen: isOpenConfirmBeforeSubmit, handleOpen: handleOpenConfirmBeforeSubmit, handleClose: handleCloseConfirmBeforeSubmit} = useOpenModal();

    useEffect(() => {
        handleOpen();

        return () => handleClose();
        // eslint-disable-next-line
    }, []);

    let closeTimeout;
    const handleCloseAndNavigate = () => {
        clearTimeout(closeTimeout);
        handleClose();
        closeTimeout = setTimeout(() => {
            navigate(`/patient/${patientUUID}/vragenlijst/${questionnaireUUID}`)
        }, 200)
    }

    // Handle inner modal: introduction step, question steps & scroll to top of modal
    const innerRef = useRef(null);
    const [stepIndex, setStepIndex] = useState(0);
    const currentStepData = questionsData?.steps?.[stepIndex];
    const [showIntroduction, setShowIntroduction] = useState(true);
    const [shouldSubmitForm, setShouldSubmitForm] = useState(false);
    const [savingMidTerm, setSavingMidTerm] = useState(false);

    const scrollToTop = () => {
        innerRef?.current?.scroll({
            top: 0,
        });
    };

    const handleStartForm = () => {
        setShowIntroduction(false);
        scrollToTop();
    }

    const handlePreviousClick = () => {
        setStepIndex(stepIndex - 1);
        scrollToTop();
    }

    const extendedHandleCloseConfirmBeforeSubmit = () => {
        setShouldSubmitForm(false);
        handleCloseConfirmBeforeSubmit();
    }

    const saveMidTerm = () => {
        const filteredData = getFormData(getValues())
        filteredData.shouldSaveMidTerm = true;
        setSavingMidTerm(true)
        fetcher.submit(filteredData, { method: "POST", action: `/patient/${patientUUID}/vragenlijst/${questionnaireUUID}/invullen`})
    }

    const shouldNavigateToNextStep = !showIntroduction && stepIndex + 1 < questionsData?.steps?.length;
    const shouldSoftSubmitForm = !showIntroduction && stepIndex + 1 >= questionsData?.steps?.length;
    const canSaveMidTerm = !showIntroduction && questionsData?.canSaveMidTerm;

    // Get and set form values in context and handle submit
    const {questionnaireValues, updateQuestionnaireValues, removeSpecificQuestionnaireValues} = useQuestionnaireContext();

    const {register, handleSubmit, formState: { errors, isDirty }, getValues, watch} = useForm({
        defaultValues: {
            intent: "fill-in-questionnaire",
            ...questionnaireValues?.[patientUUID]?.[questionnaireUUID],
        }
    });

    const onFormFieldBlur = () => {
        const values = getValues();
        updateQuestionnaireValues(values, patientUUID, questionnaireUUID);
    }

    const fetcher = useFetcher();
    const [status, setStatus] = useState(Statuses.IDLE);
    const [error, setError] = useState(false);
    const disabled = status === Statuses.SUBMITTING || savingMidTerm;

    const onSubmit = (data) => {
        if(shouldNavigateToNextStep) {
            setStepIndex(stepIndex + 1);
            scrollToTop();
        }

        if (shouldSoftSubmitForm && !shouldSubmitForm) {
            setShouldSubmitForm(true);
            handleOpenConfirmBeforeSubmit();
        }

        if(shouldSubmitForm) {
            setStatus(Statuses.SUBMITTING);
            setError(false);

            const filteredData = getFormData(data)

            fetcher.submit(filteredData, { method: "POST", action: `/patient/${patientUUID}/vragenlijst/${questionnaireUUID}/invullen`})
        }
    }

    const getFormData = (data) => {
        return Object.fromEntries(
            Object.entries(data).filter(([k, _]) => !k.startsWith('other-')).map(([key, value]) => {

                if (canSaveMidTerm) {
                    const question = questionsData.steps.reduce((accumulator, currentValue) => {
                        return accumulator.concat(currentValue.items)
                    }, []).find(i => i.id === key)

                    // checkboxes value should always be an array
                    if (['Checkboxes'].includes(question?.type)) {
                        if (typeof value === 'string') {
                            value = [value]
                        }
                    }
                }

                if (data.hasOwnProperty(`other-${value}`) && !Array.isArray(value)) {
                    return [key, JSON.stringify([{
                        id: value.replace('other-', ''),
                        value: data[`other-${value}`]
                    }])]
                } else if (Array.isArray(value)) {
                    // Format the checkbox array to the expected JSON structure for CheckboxesField
                    const arr = value.map((id) => {
                        if (data.hasOwnProperty(`other-${id}`)) {
                            return {
                                id,
                                value: data[`other-${id}`]
                            };
                        } else {
                            return { id };
                        }
                    });
                    return [key, arr?.length > 0 ? JSON.stringify(arr) : JSON.stringify([])];
                } else if (typeof value === 'object' && value !== null) {
                    // Filter out unchecked RadioButtonsField
                    return [key, value?.id ? JSON.stringify(value) : JSON.stringify({})];
                } else {
                    // Filter out null values
                    return [key, value ? value : ""];
                }
            })
        );
    }

    // Fetcher callback
    useEffect(() => {
        if(fetcher?.state === "idle") {
            if(fetcher?.data?.error) {
                setStatus(Statuses.IDLE);
                return setError(true);
            }

            if (fetcher?.data) {
                handleCloseConfirmBeforeSubmit();
                removeSpecificQuestionnaireValues(patientUUID, questionnaireUUID);
                setSavingMidTerm(false)

                if (fetcher.data?.completedAt) {
                    handleCloseAndNavigate();
                }
            }
        }
        //eslint-disable-next-line
    }, [fetcher.state, fetcher.data]);

    // Scroll spy
    const sectionRefs = [useRef(null), useRef(null), useRef(null)];

    const activeSection = useScrollSpy({
        sectionElementRefs: sectionRefs,
        offsetPx: -100,
        scrollingElement: innerRef,
        throttleMs: 0
    });

    return (
        <FormModal id="questions-form-modal" isOpen={isOpen} handleClose={handleCloseAndNavigate} identifier={isDiva ? "Diva" : null} ref={innerRef} doNotCloseOnClickUnderlay>
            <ConfirmBeforeCloseModal handleCloseAndNavigate={handleCloseAndNavigate} isDirty={isDirty} />
            <ModalHeader>
                <PatientName title={`Naam: ${patient}}`}><strong>Naam:</strong> {patient}</PatientName>
                {showIntroduction ? <Steps>{questionsData?.title}</Steps> : <Steps>Stap {stepIndex + 1} / {questionsData?.steps?.length}</Steps>}
                <EmptyBox />
            </ModalHeader>

            {showIntroduction ? (
                <MarkdownText text={questionsData?.description} $noMargin />
            ) : (
                <>
                    {(isDiva && stepIndex === 0) &&
                        <TableOfContents>
                            <TableOfContentsItem to="deel-1" containerId="questions-form-modal" $active={activeSection === 0}>Deel 1</TableOfContentsItem>
                            <TableOfContentsItem to="deel-2" containerId="questions-form-modal" $active={activeSection === 1}>Deel 2</TableOfContentsItem>
                            <TableOfContentsItem to="deel-3" containerId="questions-form-modal" $active={activeSection === 2}>Deel 3</TableOfContentsItem>
                        </TableOfContents>
                    }

                    <StepTitle>{currentStepData?.title}</StepTitle>
                    <Text $noMargin>{currentStepData?.description}</Text>

                    <form id="fill-in-questionnaire-form" onSubmit={handleSubmit(onSubmit)}>
                        {isDiva ? (
                            <DivaFormFields
                                questionsData={questionsData}
                                stepIndex={stepIndex}
                                currentStepData={currentStepData}
                                errors={errors}
                                register={register}
                                watch={watch}
                                onFormFieldBlur={onFormFieldBlur}
                                getValues={getValues}
                                identifier={questionsData?.identifier}
                                sectionRefs={sectionRefs}
                            />
                        ) : (
                            <DefaultFormFields
                                currentStepData={currentStepData}
                                errors={errors}
                                register={register}
                                watch={watch}
                                onFormFieldBlur={onFormFieldBlur}
                            />
                        )}
                    </form>
                </>
            )}

            <FormModal.Footer>
                {stepIndex === 0 ? <Box /> : <Box><StyledButton type="button" onClick={handlePreviousClick} $variant="secondary">Vorige</StyledButton></Box>}
                {(showIntroduction) && <Box><StyledButton type="button" onClick={handleStartForm}>{questionsData?.buttonText}</StyledButton></Box>}
                {canSaveMidTerm && <Box><Button $width={'100%'} type="button" onClick={saveMidTerm} disabled={disabled} loading={savingMidTerm}>Opslaan</Button></Box>}
                {shouldNavigateToNextStep && <Box><StyledButton type="submit" form="fill-in-questionnaire-form">Volgende</StyledButton></Box>}
                {shouldSoftSubmitForm && <Box><Button $width={'100%'} type="submit" form="fill-in-questionnaire-form" disabled={disabled}>Afronden</Button></Box>}
                {shouldSoftSubmitForm && <ConfirmBeforeSubmitModal isOpen={isOpenConfirmBeforeSubmit} handleClose={extendedHandleCloseConfirmBeforeSubmit} status={status} error={error} form="fill-in-questionnaire-form" />}
            </FormModal.Footer>
        </FormModal>
    );
}

export default Questions;