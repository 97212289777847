import {checkRequiredAccount} from '../Root/Root';
import {InfoList, InfoListItem, InfoListWrapper} from '../../components/InfoList/InfoList';
import {useRouteLoaderData} from 'react-router-dom';
import styled from 'styled-components';
import {Alert} from '../../components/Alert/Alert';
import {CareTeam} from './Partials/CareTeam';

const StyledInfoListWrapper = styled(InfoListWrapper)`
    grid-template-columns: 1fr 1fr 0.75fr 1fr;
`;

export async function summaryLoader({request, params}) {
    // TODO check permissions
    await checkRequiredAccount();

    return true;
}

// TODO de keys voor alle velden nalopen
const Summary = () => {
    const {patientData} = useRouteLoaderData("patient");

    return (
        <>
            <StyledInfoListWrapper>
                <InfoList>
                    <InfoListItem label="Locatie">{patientData?.location?.[0] && patientData.location[0]}</InfoListItem>
                    <InfoListItem label="Comorbiditeit">{patientData.comorbidity}</InfoListItem>
                    <InfoListItem label="Behandeltraject">{patientData.careTrajectory}</InfoListItem>
                    <CareTeam />
                </InfoList>
                <InfoList>
                    <InfoListItem label="Primaire diagnose">{patientData.primaryDiagnosis}</InfoListItem>
                    <InfoListItem label="Zorgvraagtype">{patientData.careDemandType}</InfoListItem>
                    <InfoListItem label="Start behandeling">{patientData.startCare}</InfoListItem>
                </InfoList>
                <InfoList>
                    <InfoListItem label="Hartslag">{patientData.heartRate}</InfoListItem>
                    <InfoListItem label="Bloeddruk">{patientData.bloodPressure}</InfoListItem>
                </InfoList>
                <InfoList>
                    {/*TODO*/}
                    <InfoListItem label="Aantal no-shows" wrapInText={false}>{patientData.noShows && <Alert>4 (Medco; 2, CGT: 2</Alert>}</InfoListItem>
                    <InfoListItem label="Medicatie">{patientData.medication}</InfoListItem>
                    <InfoListItem label="Medische bijzonderheden">{patientData.medicalDetails}</InfoListItem>
                </InfoList>
            </StyledInfoListWrapper>
        </>
    );
}

export default Summary;