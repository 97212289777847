import React, {useEffect} from 'react';
import {Heading1} from '../../components/Heading/Heading';
import {Text} from '../../components/Text/Text';
import {Await, defer, NavLink, Outlet, useLoaderData, useParams, useRouteLoaderData} from 'react-router-dom';
import styled from 'styled-components';
import {fetchProtectedData} from '../../api/fetch';
import {ReactComponent as Medicore} from '../../assets/medicore-icon.svg';
import {checkRequiredAccount} from '../Root/Root';
import {calculateAge, checkAccountPermissions} from '../../utils/helpers';
import {Permissions} from '../../constants/enums';
import {CustomLink} from '../../components/CustomLink/CustomLink';
import {CopyButton} from '../../components/CopyButton/CopyButton';
import {SyncButton} from './Partials/SyncButton';
import {useLocalStorage} from '../../hooks/useLocalStorage';
import {BackButton} from '../../components/BackButton/BackButton';
import {getHomePage} from '../Login/Login';
import {Timeline} from '../../components/Timeline/Timeline';
import {Spinner} from '../../components/Spinner/Spinner';

const StyledBackButton = styled(BackButton)`
    margin-top: 10px;
    margin-bottom: 26px;
    color: var(--color-primary);
    
    &:hover {
        color: var(--color-primary-hover);
    }
`;

const Tabs = styled.div`
    display: flex;
    gap: 4px;
    margin: 50px 0 30px;
    padding-bottom: 30px;
    border-bottom: 2px solid var(--color-divider);
`;

const Tab = styled.button`
    background-color: ${({$isActive}) => $isActive ? 'var(--color-tab)' : 'transparent'};
    color: var(--color-text);
    border-radius: 30px;
    cursor: pointer;
    padding: 8px 20px;
    font-weight: var(--fw-bold);
    font-size: var(--fs-button);
    text-decoration: none;
    line-height: 29px;
    text-align: center;
    border: none;

    &:hover {
        background-color: var(--color-tab);
    }
`;

const FlexHeading = styled.header`
    display: flex;
    justify-content: space-between;
    gap: 10px;
    flex-wrap: wrap;
`;

const PatientID = styled(Text).attrs({
    $noMargin: true,
    $small: true
})`
    margin-bottom: 6px;
`;

const PatientName = styled(Heading1).attrs({
    $noMargin: true,
})`
    margin-bottom: 10px;
`;

const PatientActions = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;

    svg {
        display: block;
    }
`;

const TimelineWrapper = styled.div`
    margin: 30px 0 0;
`;

const TimelineFallback = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 291px;
    background-color: var(--color-white);
    box-shadow: var(--box-shadow);
    border-radius: 10px;
`;

export async function patientLoader({request, params}) {
    await checkRequiredAccount(Permissions.PATIENT_READ);

    const patientPromise = fetchProtectedData(request,`patient/${params.patientUUID}`);
    const timelinePromise = fetchProtectedData(request,`patient/${params.patientUUID}/timeline`);

    return defer({patientData: await patientPromise, timelinePromise});
}

const Patient = () => {
    const {account} = useRouteLoaderData("root");
    const {patientData, timelinePromise} = useLoaderData();
    const {patientUUID} = useParams();

    // TODO permissions
    const tabs = [
        {
            title: "Samenvatting",
            slug: `/patient/${patientUUID}`,
            end: true,
        },
        {
            title: "Personalia",
            slug: "personalia",
            end: true,
        },
        checkAccountPermissions(account, Permissions.PATIENTQUESTIONNAIRE_VIEW) && {
            title: "Vragenlijsten",
            slug: "vragenlijsten",
            end: true,
        },
        checkAccountPermissions(account, Permissions.DOCUMENT_VIEW) && {
            title: "Documenten",
            slug: "documenten",
            end: true,
        },
        checkAccountPermissions(account, Permissions.NOTIFICATION_VIEW) && {
            title: "Berichten",
            slug: "berichten",
            end: false,
        },
        checkAccountPermissions(account, Permissions.APPOINTMENT_VIEW) && {
            title: "Diagnostiekdag",
            slug: "afspraken",
            end: true,
        }
    ].filter(Boolean);

    // Set the user in localStorage for search history
    const [, setRecentlyViewedPatients] = useLocalStorage("recentlyViewedPatients", { type: "array", parentKey: account.id, maxLength: 8 })
    useEffect(() => {
        setRecentlyViewedPatients(patientData.id)
        //eslint-disable-next-line
    }, []);

    return (
        <>
            <StyledBackButton to={getHomePage(account)}>Patiëntenoverzicht</StyledBackButton>
            <FlexHeading>
                <div>
                    <PatientID>ID: {patientData?.medicoreID}</PatientID>
                    <PatientName>{patientData?.name}</PatientName>
                    <Text $noMargin $small>{patientData?.birthDate && `${patientData?.birthDate}, ${calculateAge(patientData?.birthDate)}`}</Text>
                </div>

                <PatientActions>
                    <SyncButton patientUUID={patientUUID} />
                    <CopyButton />
                    {patientData?.medicoreUrl && (
                        <CustomLink to={patientData.medicoreUrl} type="external">
                            <Medicore />
                        </CustomLink>
                    )}
                </PatientActions>
            </FlexHeading>

            <TimelineWrapper>
                <React.Suspense fallback={
                    <TimelineFallback>
                        <Spinner />
                    </TimelineFallback>
                }>
                    <Await resolve={timelinePromise} errorElement={<Text>De tijdlijn kan niet worden opgehaald. Probeer het opnieuw.</Text>}>
                        {(timelineData) => (
                            <Timeline data={timelineData} />
                        )}
                    </Await>
                </React.Suspense>
            </TimelineWrapper>

            <Tabs>
                {tabs.map(item => (
                    <NavLink key={item.slug} to={item.slug} end={item.end}>
                        {({isActive}) => (
                            <Tab $isActive={isActive}>
                                {item.title}
                            </Tab>
                        )}
                    </NavLink>
                ))}
            </Tabs>

            <Outlet />
        </>
    );
}

export default Patient;