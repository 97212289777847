import {checkRequiredAccount} from '../Root/Root';
import {useRouteLoaderData} from 'react-router-dom';
import styled, {css} from 'styled-components';
import {ReactComponent as Trash} from '../../assets/icons/trash.svg';
import {ReactComponent as Edit} from '../../assets/icons/edit.svg';
import {useOpenModal} from '../../hooks/useOpenModal';
import React from 'react';
import {DeleteAppAccount} from './Partials/DeleteAppAccount';
import {AddAppAccount} from './Partials/AddAppAccount';
import {deleteData, postData} from '../../api/fetch';
import {InfoList, InfoListItem, InfoListWrapper} from '../../components/InfoList/InfoList';

const iconStyles = css`
    width: 20px;
    height: 20px;
    vertical-align: top;
    cursor: pointer;
    margin-left: 8px;
`;

const TrashIcon = styled(Trash)`
    ${iconStyles};
`;

const EditIcon = styled(Edit)`
    ${iconStyles};
`;

export async function personalDetailsAction({request, params}) {
    let formData = await request.formData();
    let intent = formData.get("intent");

    switch(intent) {
        case "add-app-account": {
            const body = {
                "phoneNumber": formData.get("phoneNumber"),
                "email": formData.get("email")
            }

            return await postData(request, `patient/${params.patientUUID}/account`, JSON.stringify(body))
        }

        case "delete-app-account": {
            return await deleteData(request, `patient/${params.patientUUID}/account`);
        }

        default:
            return {"default": true};
    }
}

export async function personalDetailsLoader({request, params}) {
    // TODO check permissions
    await checkRequiredAccount();

    return true;
}

const PersonalDetails = () => {
    const {patientData} = useRouteLoaderData("patient");
    const {isOpen: isOpenDelete, handleOpen: handleOpenDelete, handleClose: handleCloseDelete} = useOpenModal();
    const {isOpen: isOpenAdd, handleOpen: handleOpenAdd, handleClose: handleCloseAdd} = useOpenModal();

    return (
        <>
            <InfoListWrapper>
                <InfoList>
                    <InfoListItem label="Voornamen">{patientData.firstNames}</InfoListItem>
                    <InfoListItem label="Achternaam">{patientData.lastName}</InfoListItem>
                </InfoList>
                <InfoList>
                    <InfoListItem label="Telefoonnummer mobiel">{patientData.phoneNumberMobile}</InfoListItem>
                    <InfoListItem label="Telefoonnummer thuis">{patientData.phoneNumberHome}</InfoListItem>
                    <InfoListItem label="Telefoonnummer werk">{patientData.phoneNumberWork}</InfoListItem>
                </InfoList>
                <InfoList>
                    <InfoListItem label="E-mailadres">{patientData.email}</InfoListItem>
                    <InfoListItem label="Adres">{patientData.address}</InfoListItem>
                    <InfoListItem label="BSN">{patientData.bsn}</InfoListItem>
                </InfoList>
                <InfoList>
                    <InfoListItem label="Verzekering">{patientData.healthInsurance}</InfoListItem>
                    <InfoListItem label="Aanwezig app account">{patientData?.account ? <>Ja<TrashIcon onClick={handleOpenDelete} /></> : <>Nee<EditIcon onClick={handleOpenAdd} /></>}</InfoListItem>
                    <InfoListItem label="Laatste app sessie">{patientData.lastAppSession}</InfoListItem>
                </InfoList>
            </InfoListWrapper>

            <DeleteAppAccount isOpen={isOpenDelete} handleClose={handleCloseDelete} />
            <AddAppAccount isOpen={isOpenAdd} handleClose={handleCloseAdd} />
        </>
    );
}

export default PersonalDetails;